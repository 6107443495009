import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Contact from './Contact'
const Video = () => {
  return (
    <div>
      <h1>Video</h1>
      <Router>
        <Routes>
          <Route path="/video/palmtrees" element={Contact} />
        </Routes>
      </Router>
    </div>
  )
}

export default Video
