import React from 'react'

const Images = () => {
  return (
    <div>
      <p>Images will go here</p>
    </div>
  )
}

export default Images
