import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { createBrowserHistory } from 'history'

// Parts
import Header from './parts/Header'
import Footer from './parts/Footer'
// Components
import Home from './components/Home'
import Bio from './components/Bio/Bio'
import Music from './components/Music'
import Video from './components/Video'
import Images from './components/Images'
import Story from './components/Story'
import Contact from './components/Contact'

// Main stylesheet, and there probably won't be another one
import 'bootstrap/dist/css/bootstrap.min.css'
import './styling/main.css'

const history = createBrowserHistory()
function App() {
  return (
    <div className="App">
      <Header />
      {/* Routing */}
      <div className="main">
        <Router history={history}>
          <Routes>
            <Route path="/bio" element={<Bio />} />
            <Route path="/music" element={<Music />} />
            <Route path="/video" element={<Video />} />
            <Route path="/video/:_id" element={<Video />} />
            <Route path="/images" element={<Images />} />
            <Route path="/story" element={<Story />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Router>
      </div>
      <Footer />
    </div>
  )
}

export default App
