import React from 'react'

import {
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

// assets
import JimmyKidd from '../jimmykidd.png'

const Header = () => {
  return (
    <header className="header">
      <Navbar>
        <Nav>
          <NavItem>
            <NavLink href="/">home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/bio">bio</NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink href="/music">music</NavLink>
          </NavItem> */}
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              video
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem>North Stars</DropdownItem>
              <DropdownItem>The First Sons</DropdownItem>
              <DropdownItem>Black Spots</DropdownItem>
              <DropdownItem>Aim</DropdownItem>
              <DropdownItem>1852</DropdownItem>
              <DropdownItem>Corvus</DropdownItem>
              <DropdownItem>Sirens</DropdownItem>
              <DropdownItem>Atlas, Always</DropdownItem>
              <DropdownItem href="/video/palmtrees">Palm Trees</DropdownItem>
              <DropdownItem>Set Sail for High Seas</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          {/* <NavItem>
            <NavLink href="/images">images</NavLink>
          </NavItem> */}
          {/* <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              story
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem>The Ballad of Jimmy Kidd</DropdownItem>
              <DropdownItem>North Stars</DropdownItem>
              <DropdownItem>The First Sons</DropdownItem>
              <DropdownItem>Black Spots</DropdownItem>
              <DropdownItem>Aim</DropdownItem>
              <DropdownItem>1852</DropdownItem>
              <DropdownItem>Corvus</DropdownItem>
              <DropdownItem>Sirens</DropdownItem>
              <DropdownItem>Atlas, Always</DropdownItem>
              <DropdownItem>Palm Trees</DropdownItem>
              <DropdownItem>Set Sail for High Seas</DropdownItem>
            </DropdownMenu> 
           </UncontrolledDropdown> */}
          <NavItem>
            <NavLink href="/contact">contact</NavLink>
          </NavItem>
        </Nav>
        <NavbarBrand href="/">
          <img
            src={JimmyKidd}
            className="mainImage"
            alt="Jimmy Kidd"
            width="250"
          />
        </NavbarBrand>
      </Navbar>
    </header>
  )
}

export default Header
