import React from 'react'

const Story = () => {
  return (
    <div>
      <p>Story will go here</p>
      {/* Switch */}
    </div>
  )
}

export default Story
