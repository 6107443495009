import React from 'react'
import BioPic from './bio.jpeg'

const Bio = () => {
  return (
    <div className="container">
      <h1>Bio</h1>
      <div className="row">
        <p className="col-md-6">
          <strong>Jimmy Kidd</strong> is an Antwerp-based musician and
          performer. <br /> With their synth-based music they invite you to lose
          yourself in a fantasy world of spaceships and pirates. <br />A world
          where they had to disguise their gender to work on a ship and grew to
          like this bending of gender. <br />
          Jimmy creates unpredictable, messy and comfortably intimate shows that
          always have a deeper message if you know where to look. <br />
          <strong>X marks the spot.</strong>
        </p>
        {/* TODO: image */}
        <img className="col-md-5" src={BioPic} alt="Jimmy Kidd" height="80%;" />
      </div>
      <hr />
      <h2>Friends of Jimmy:</h2>
      <p>Jimmy is a proud collaborator of</p>
      <a
        href="https://partytrashers.com/"
        target="_blank"
        className="biolink"
        rel="noreferrer"
      >
        Partytrashers
      </a>
      <br />
      <a
        href="https://www.instagram.com/quatsch_collective/"
        target="_blank"
        className="biolink"
        rel="noreferrer"
      >
        Quatsch Collective
      </a>
      <br />
      <a
        href="https://crankyandthelaw.com/"
        target="_blank"
        className="biolink"
        rel="noreferrer"
      >
        Cranky & The Law
      </a>
    </div>
  )
}

export default Bio
