import React from 'react'

const Contact = () => {
  return (
    <div>
      <h1>Contact</h1>
      <iframe
        title="contactForm"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfdZooFIGHQDtl1iSHuxpSxxKoVbRgDe2DZ8DIqLyf4I5SrtA/viewform?embedded=true"
        width="640"
        height="407"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Laden…
      </iframe>
    </div>
  )
}

export default Contact
