import React from 'react'

const Home = () => {
  return (
    <div className="container content">
      <h1>Debut album 'The Ballad of Jimmy Kidd' coming soon.</h1>
      <p>
        Want to stay up to date? Register for the newsletter here. <br />
        You won't get more than 1 email per month.
      </p>
      <iframe
        title="newsletterForm"
        src="https://docs.google.com/forms/d/e/1FAIpQLSfz5cOfkOjuwhdYDP17RnL_W9XyAJR83HMzvpEnTAYdPhF3DQ/viewform?embedded=true"
        width="80%"
        height="600"
        frameborder="0"
        marginheight="0"
        marginwidth="0"
      >
        Laden…
      </iframe>
    </div>
  )
}

export default Home
