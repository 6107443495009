import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpotify,
  faYoutube,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'

import { Navbar, NavItem, Nav, NavLink } from 'reactstrap'

const Footer = () => {
  return (
    <footer className="footer">
      <Navbar position="static">
        <Nav>
          {/* TODO: spotify link */}
          {/* <NavItem>
            <NavLink target="_blank" href="">
              <FontAwesomeIcon icon={faSpotify} />
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              target="_blank"
              href="https://www.youtube.com/@jimmykidd6011"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              target="_blank"
              href="https://www.instagram.com/thejimmykidd/"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    </footer>
  )
}

export default Footer
