import React from 'react'

const Music = () => {
  return (
    <div>
      <h1>Music</h1>
      {/* Testing media player */}
      {/* TODO: where to host the songs? S3? */}
    </div>
  )
}

export default Music
